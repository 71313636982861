$color_content_primary: #281D35;
$color_content_secondary: #7C7282;
$color_content_teriary: #DDDDDD;
$color_content_quaternary: #EEEEEE;
$color_content_quinary: #F6F6F6;
$color_content_gradient: linear-gradient(90deg, #D94179, #F29966);
$color_content_gradient_fallback: #D94179;

$color_surface_default: #FFFFFF;
$color_surface_elevated: #FFFFFF;

$color_button_primary: #FF3876;
$color_button_default: #282828;
$color_error: #DD4A68;
$color_green: #21CE99;
$color_red: #D94179;
$color_button_dark: #281D35;

$color_content_primary_onDark: #F8F8F8;
$color_content_secondary_onDark: #ADADAD;
$color_content_teriary_onDark: #494949;
$color_content_quaternary_onDark: #393939;
$color_content_quinary_onDark: #303030;

$color_surface_default_onDark: #000000;
$color_surface_elevated_onDark: #282828;