@import './theme.scss';

.FieldRow {
  padding: 24px 4%;
  margin: 0 -4%;
  transition: .4s;
  border-radius: 12px;
  display: flex;
}

.FieldRow.Clickable {
  cursor: pointer;
}

.FieldRow.Clickable:hover, .FieldRow.Clickable:focus {
  background-color: rgba(217, 65, 121, 0.1);
}

.FieldIcon {
  color: $color_red;
  width: 36px;
  padding-top: 2px;
}

.FieldRowClickableTitle {
  font-weight: 600;
}

.FieldRowSubtitle {
  font-size: 12px;
  line-height: 15px;
  padding-top: 8px;
  color: $color_content_secondary
}