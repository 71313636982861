@import '../common/theme.scss';

#wallet_create_cta {
  width: 90%;
  max-width: 640px;
  margin: 16px auto 24px auto;
  display: flex;
}


#wallet_create_button {
  margin-right: 0;
  margin-left: auto;
}

.WalletRow {
  padding: 24px 4%;
  margin: 0 -4%;
  border-top: 1px solid $color_content_quinary;
  transition: .4s;
  border-radius: 12px;
}

.WalletRow:nth-child(2) {
  border-top: none;
  padding-top: 40px;
}

.WalletRow.Clickable {
  cursor: pointer;
}

.WalletRow.Clickable:hover, .WalletRow.Clickable:focus {
  background-color: rgba(217, 65, 121, 0.1);
}

.WalletRowIdInfoContainer {
  display: flex;
}

.WalletRowWalletName {
  align-self: center;
}

.WalletRowIsPrimaryTag {
  margin-left: auto;
}

.WalletRowCoinAddress {
  font-size: 12px;
  line-height: 15px;
  padding-top: 8px;
  color: $color_content_secondary;
}

.WalletRowCtaContainer {
  display: flex;
  margin-top: 20px;
}

.WalletRowDeactivateCta {
  margin-left: auto;
}
