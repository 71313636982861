@import './theme.scss';


.ModalContainer {
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0);
  transition: .4s;
  display: none;
  z-index: 100;
}

.ModalContainer.open {
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
}

.Modal {
  position: absolute;
  width: 420px;
  max-width: 90%;
  top: 40%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 12px;
  box-shadow: 1px 2px 6px $color_content_secondary;
}


@media screen and (max-width: 768px) {
  .Modal {
    width: 100%;
    max-width: 100%;
    top: auto;
    left: 0;
    bottom: 0;
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    border-radius: 12px 12px 0 0;
  }
}