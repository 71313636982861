@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Fraunces:ital,wght@0,300;0,400;1,300;1,400&display=swap');

@layer utilities {
  .pb-safe {
    padding-bottom: env(safe-area-inset-bottom);
  }
}

body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div {
  margin: 0;
}

h1 {
  font-family: 'Fraunces';
  font-weight: 200;
  font-size: 36px;
  line-height: 40px;
  letter-spacing: -0.036em;
  color: #281D35;
  margin: 0;
}

p {
  font-size: 16px;
  line-height: 26px;
  font-family: 'Inter';
  font-weight: 400;
  letter-spacing: -0.01em;
  color: #281D35;
}

.main_griffin_container {
  width: 90%;
  max-width: 640px;
  margin: 32px auto;
}

.main_griffin_container_extra_bottom_padding {
  margin-bottom: 80px;
}

.main_griffin_width_container {
  width: 90%;
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;
}
