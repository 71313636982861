@import './theme.scss';

#topbar {
  width: 90%;
  max-width: 640px;
  margin: auto;
  display: flex;
  justify-content: space-between;
}

#topbar_logo {
  height: 24px;
  margin-top: 20px;
  margin-bottom: 20px;
  float: left;
  cursor: pointer;
}

.topbar_navlink {
  float: right;
  list-style: none;
  padding: 23px 0;
  margin: 0 -15px;
}

.topbar_navlink li {
  display: inline-block;
}

.topbar_navlink li a {
  text-decoration: none;
  transition: all 0.3s ease 0s;
  color: $color_content_secondary;
  font-size: 12px;
  line-height: 18px;
  padding: 10px 15px;
}

.topbar_navlink li a:hover, .topbar_navlink li a.active {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: $color_content_primary;
}