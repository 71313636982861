@import './theme.scss';

.input_field {
  display: flex;
  flex-direction: column;
  margin: 16px 0;
}

.input_field input,
.input_field input:-webkit-autofill,
.input_field input:-webkit-autofill:hover, 
.input_field input:-webkit-autofill:focus  {
  width: 100%;
  height: 72px;
  padding: 22px 25px 0 25px;
  position: relative;
  border: 1px solid rgba(11, 34, 54, 0.1);
  border-radius: 12px;
  background: #fff;
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: $color_content_primary;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
}

.input_field input:focus {
  border: 2px solid $color_content_primary;
  outline: none;
}

.input_field label {
  position: absolute;
  transform: translate(25px, 26px) scale(1);
  transform-origin: top left;
  transition: all 0.2s ease-out;
  font-family: 'Inter';
  font-size: 16px;
  line-height: 19px;
  color: $color_content_secondary;
  touch-action: manipulation;
}

.input_field:focus-within label, label.active {
  transform: translate(25px, 15px) scale(0.75);
}

.input_field input:-webkit-autofill + label,
.input_field input:-webkit-autofill:hover + label, 
.input_field input:-webkit-autofill:focus + label {
  transform: translate(25px, 15px) scale(0.75);
}
