@import './theme.scss';

button {
  font-family: 'Inter';
  font-weight: 600;
  transition: all 200ms linear;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  outline: 0;
  box-shadow: none;
  cursor: pointer;
}

.button_primary {
  color: white;
  background-color: $color_button_primary;
  border-radius: 12px;
  border: none;
}

.button_default {
  color: white;
  background-color: $color_button_default;
  border-radius: 12px;
  border: none;
}

.button_hairline {
  color: $color_content_primary;
  background-color: transparent;
  border-radius: 12px;
  border: 1px solid $color_content_primary;
}

.button_hairline_onDark {
  color: $color_content_primary_onDark;
  background-color: transparent;
  border-radius: 12px;
  border: 1px solid $color_content_primary_onDark;
}

.button_underline {
  color: $color_content_primary;
  background-color: transparent;
  border: none;
  text-decoration: underline;
  text-underline-offset: 2px;
}

.button_underline_onDark {
  color: $color_content_primary_onDark;
  background-color: transparent;
  border: none;
  text-decoration: underline;
  text-underline-offset: 2px;
}

.button_text {
  color: $color_content_primary;
  background-color: transparent;
  border: none;
}

.button_dark {
  color: $color_content_primary_onDark;
  background-color: $color_button_dark;
  border-radius: 12px;
  border: none;
}

.button_primary:disabled, .button_primary[disabled],
.button_default:disabled, .button_default[disabled],
.button_hairline:disabled, .button_hairline[disabled],
.button_hairline_onDark:disabled, .button_hairline_onDark[disabled],
.button_underline:disabled, .button_underline[disabled],
.button_underline_onDark:disabled, .button_underline_onDark[disabled],
.button_text:disabled, .button_text[disabled] {
  opacity: 0.3;
  cursor: not-allowed;
}

.button_primary:focus, .button_primary:hover {
  background-color: darken($color_button_primary, 15%);
}

.button_default:focus, .button_default:hover {
  background-color: darken($color_button_default, 15%);
}

.button_hairline:focus, .button_hairline:hover {
  color: $color_button_primary;
  border: 1px solid $color_button_primary;
}

.button_hairline_onDark:focus, .button_hairline_onDark:hover {
  color: $color_content_primary;
  background-color: $color_content_primary_onDark;
}

.button_underline:focus, .button_underline:hover,
.button_text:focus, .button_text:hover {
  color: $color_button_primary;
}

.button_underline_onDark:focus, .button_underline_onDark:hover,
.button_text_onDark:focus, .button_text_onDark:hover {
  color: $color_content_secondary_onDark;
}

.button_size_default {
  font-size: 16px;
  line-height: 19px;
}

.button_size_small {
  font-size: 14px;
  line-height: 17px;
}

.button_primary.button_size_default, .button_default.button_size_default, .button_dark.button_size_default, .button_hairline.button_size_default, .button_hairline_onDark.button_size_default {
  padding: 20px 24px;
}

.button_primary.button_size_small, .button_default.button_size_small, .button_dark.button_size_small, .button_hairline.button_size_small, .button_hairline_onDark.button_size_small {
  padding: 12px 16px;
}

.button_underline.button_size_default, .button_text.button_size_default, .button_underline_onDark.button_size_default, .button_text_onDark.button_size_default  {
  padding: 20px 0;
}

.button_underline.button_size_small, .button_text.button_size_small, .button_underline_onDark.button_size_small, .button_text_onDark.button_size_small {
  padding: 12px 0;
}

.button_width_full_screen {
  width: 100%;
}