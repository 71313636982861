@import './theme.scss';

.toggle_container {
  width: 76px;
  height: 40px;
  border-radius: 40px;
  background-color: $color_content_quaternary;
  transition: .3s;
  -webkit-transition: .3s;
  cursor: pointer;
  position: relative;
}

.toggle_selector {
  width: 36px;
  height: 36px;
  left: 2px;
  top: 2px;
  border-radius: 36px;
  position: absolute;
  background-color: #FF3876;
  transition: .3s;
  -webkit-transition: .3s;
}

.toggle_container.checked .toggle_selector{
  width: 36px;
  transform: translateX(36px);
}

.toggle_unchecked_label {
  position: absolute;
  left: 2px;
  top: 2px;
  width: 36px;
  text-align: center;
  line-height: 36px;
  font-family: 'Inter';
  font-weight: 600;
  font-size: 18px;
  color: white;
}

.toggle_container.checked .toggle_unchecked_label {
  color: $color_content_primary;
  font-size: 16px;
}

.toggle_container.checked .toggle_checked_label {
  color: white;
  font-size: 18px;
}

.toggle_checked_label {
  position: absolute;
  right: 2px;
  top: 2px;
  width: 36px;
  text-align: center;
  line-height: 36px;
  font-family: 'Inter';
  font-weight: 600;
  font-size: 16px;
  color: $color_content_primary;
}

// .toggle {
//   position: relative;
//   display: inline-block;
//   width: 85px;
//   height: 35px;
// }

// .toggle input { 
//   opacity: 0;
//   width: 0;
//   height: 0;
// }

// .toggle_slider {
//   border-radius: 48px;
//   position: absolute;
//   cursor: pointer;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   background-color: $color_content_quaternary;
//   -webkit-transition: .4s;
//   transition: .4s;
//   z-index: 100;
// }

// .toggle_slider:before {
//   position: absolute;
//   content: '';
//   border-radius: 48px;
//   height: 31px;
//   width: 49px;
//   left: 2px;
//   bottom: 2px;
//   background-color: white;
//   -webkit-transition: .4s;
//   transition: .4s;
//   z-index: 900;
// }

// .toggle_off_label {
//   z-index: 1000;
// }