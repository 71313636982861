@import './theme.scss';

.TagContainer {
  padding: 8px 12px;
  border-radius: 40px;
  font-size: 12px;
  line-height: 15px;
  font-weight: 600;
  color: $color_content_primary;
  border: 1px solid $color_content_secondary;
}